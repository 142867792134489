import { type FC } from 'react';

import { HistoryRouter } from 'components/HistoryRouter';
import { StoreProvider } from 'components/StoreProvider';
import { SuspenseWrapper } from 'components/suspense-wrapper/SuspenseWrapper';
import { browserHistory } from 'services/browser-history';
import { lazyWithRetry } from 'services/lazy-load-components';

const LazyGlobalModals = lazyWithRetry(() => import('components/global-modals/LazyGlobalModals'));

export const GlobalModals: FC = () => (
  <HistoryRouter history={browserHistory.browserHistoryObject}>
    <StoreProvider>
      <SuspenseWrapper fallback={null}>
        <LazyGlobalModals />
      </SuspenseWrapper>
    </StoreProvider>
  </HistoryRouter>
);

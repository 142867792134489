import createPlatformClient, { type PlatformClient } from '@livechat/platform-client';
import debug from 'debug';

import { Cookie } from 'constants/cookies';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { getConfig } from 'helpers/config';
import { getCookie } from 'helpers/cookies';
import { type IServer } from 'interfaces/server';
import { DEFAULT_RECONNECT_RETRY_COUNT } from 'services/connectivity/reconnector/events';
import { getReconnector } from 'services/connectivity/reconnector/service';
import { login } from 'services/server/login';

const log = debug(DebugLogsNamespace.AppServerConnection);

export class ServerAccessPlatform implements IServer {
  platformClient: PlatformClient<unknown, unknown>;
  accessToken: string | null = null;

  constructor(compressionEnabled: boolean) {
    const config = getConfig();
    const endpoint = `${config.api}${config.platformSocketOptions.path}`;
    const transports: ('xhr-polling' | 'websocket')[] = getCookie(Cookie.WebsocketsOff)
      ? ['xhr-polling']
      : ['websocket'];
    const rescheduleTimeout = 15 * 1000; // 15s
    const query = compressionEnabled ? { compress: compressionEnabled.toString() } : undefined;

    const options = {
      query,
      transports,
      rescheduleTimeout,
    };

    log('Creating platform client', { endpoint, options, compressionEnabled });
    this.platformClient = createPlatformClient(endpoint, options);
    this.platformClient.on('connect', () => this.handleConnect());
    this.platformClient.on('disconnect', () => this.handleDisconnect());
    this.platformClient.on('connection_unstable', () => this.handleConnectionUnstable());
  }

  on(event, handler): void {
    this.platformClient.on(event, handler);
  }

  off(event, handler): void {
    this.platformClient.off(event, handler);
  }

  connect(accessToken: string): void {
    this.accessToken = accessToken;
    this.platformClient.connect();
  }

  disconnect(): void {
    this.platformClient.disconnect();
  }

  /**
   * Always login on 'connect' event.
   * The 'connect' event may be result of manual connect but also unpredictable auto reconnects
   * which may happen during network issues or backend restart.
   * The backend restart does not send any warning push, it just closes the connection
   * making the library to reconnect to a different backend instance.
   * All new connects require to login again. Otherwise a new connection will be considered as failure
   * and agent will be disconnected after a timeout.
   */
  handleConnect(): void {
    log('Socket has connected.');

    if (!this.accessToken) {
      throw new Error('Access token not provided, cannot login.');
    }

    void login(this, this.accessToken);
  }

  /**
   * The connection was lost and auto reconnect did not work.
   * The reason might be a backend restart or network issues.
   * Reconnect again. We don't have to check internet availability
   * because the library does not send 'disconnect' event when being offline.
   */
  handleDisconnect(): void {
    log('Socket has disconnected.');

    const reconnector = getReconnector();
    void reconnector.reconnect({ reason: 'socket_disconnected', maxAttempts: DEFAULT_RECONNECT_RETRY_COUNT });
  }

  /**
   * The connection is unstable due to network issues (we're offline).
   * Reconnection should not be performed because getting back online
   * should restore the connection or emit `disconnect` event handled by different handler.
   */
  handleConnectionUnstable(): void {
    log('Socket connection is unstable.');
  }

  send(message): void {
    this.platformClient.emit(message);
  }

  isOpen(): boolean {
    return this.platformClient.getReadyState() === 1;
  }
}

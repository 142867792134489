import { type FC, useEffect } from 'react';

import { cx } from '@emotion/css';
import { useDispatch, useSelector } from 'react-redux';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { useClickOutside } from 'hooks/use-click-outside';
import { useEffectOnce } from 'hooks/use-effect-once';
import { trackEvent } from 'services/event-tracking';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getIsCopilotModalExpanded } from 'store/views/copilot/selectors';

import { CommandMenuItem } from './CommandMenuItem';
import { COMMAND_MENU_DATA_TEST_ID } from './constants';
import { type CopilotPickerOption } from './copilot-picker-option';

import * as styles from './styles';

interface IProps {
  options: CopilotPickerOption[];
  selectOption: (option: CopilotPickerOption) => void;
  setHighlightedIndex: (index: number) => void;
  selectedIndex: number | null;
  clearEditor: () => void;
}

export const CommandMenu: FC<IProps> = ({ options, selectOption, selectedIndex, setHighlightedIndex, clearEditor }) => {
  const dispatch = useDispatch();
  const isExpanded = useSelector(getIsCopilotModalExpanded);

  const { wrapperRef: menuRef } = useClickOutside(() => {
    clearEditor();
  });

  useEffect(() => {
    if (!menuRef?.current) {
      return;
    }

    const elements = menuRef?.current.getElementsByTagName('li');
    const isAnyElements = !!elements.length;
    const isValidSelectedIndex = selectedIndex !== null && selectedIndex >= 0 && selectedIndex < elements.length;

    if (isAnyElements && isValidSelectedIndex) {
      elements[selectedIndex].scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }, [menuRef, selectedIndex]);

  useEffectOnce(() => {
    dispatch(CopilotViewActions.openCommandSuggestions());
    trackEvent(CopilotTrackEvent.QuickSuggestionsDisplayed, EventPlace.One);

    return () => {
      dispatch(CopilotViewActions.closeCommandSuggestions());
    };
  });

  return (
    <div
      data-testid={COMMAND_MENU_DATA_TEST_ID}
      ref={menuRef}
      className={cx(styles.menu, 'lc-dark-theme', { [styles.popoverMenu]: !isExpanded })}
    >
      <ul>
        {options.map((option, index) => (
          <CommandMenuItem
            isSelected={selectedIndex === index}
            onMouseEnter={() => setHighlightedIndex(index)}
            key={option.title}
            option={option}
            onSelect={selectOption}
          />
        ))}
      </ul>
    </div>
  );
};
